import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpRequest, HttpClient } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule as AngularCommonModule } from '@angular/common';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { BlockUIModule } from 'ng-block-ui';
import { BlockUIHttpModule } from 'ng-block-ui/http';


export function blockUIHttpModuleRequestFilter(req: HttpRequest<any>): boolean {
  return Boolean(req.url.match(/noBlock/g));
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  // declarations: [

  // ],
  imports: [
    AngularCommonModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
    }
    }),
    BlockUIModule.forRoot({
      delayStart: 150,
      delayStop: 500
    }),
    BlockUIHttpModule.forRoot({
      requestFilters: [blockUIHttpModuleRequestFilter]
    }),
  ],
  exports: [
    AngularCommonModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    BlockUIModule,
    BlockUIHttpModule,
  ],
  providers: [

  ]
})
export class CoreModule { }
