import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-control-error',
  template: `<p *ngFor="let error of _errors" class="help text-danger font-12">{{ error.text }}</p>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./control-error.component.scss']
})
export class ControlErrorComponent implements OnInit {
  _errors: string[];
  // _text;
  // _hide = true;

  @Input() set errors(value: string[]) {
    this._errors = value;
    // if (value !== this._text) {
    //   this._text = value;
    //   this._hide = !value;
      this.cdr.detectChanges();
    // }
  }

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit() {
  }

}
