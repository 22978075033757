import { Directive, InjectionToken, Inject, Optional,
  Host, ComponentRef, ElementRef, ViewContainerRef, Input, ComponentFactoryResolver, OnInit, OnDestroy } from '@angular/core';
import { NgControl, ValidationErrors } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { merge, Observable, EMPTY } from 'rxjs';
import { FormSubmitDirective } from './form-submit.directive';
import { ControlErrorContainerDirective } from './control-error-container.directive';
import { ControlErrorComponent } from './control-error/control-error.component';


export const defaultErrors = {
    required: (error) => `This field is required`,
    minlength: ({ requiredLength, actualLength }) => `Expect ${requiredLength} but got ${actualLength}`,
    pattern:  ({ requiredPattern, actualValue }) => `Expect ${requiredPattern}  but got ${actualValue}`
}

export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
  providedIn: 'root',
  factory: () => defaultErrors
});

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '.form-control, .custom-control-input'
})
export class ControlErrorsDirective implements OnInit, OnDestroy {
  ref: ComponentRef<ControlErrorComponent>;
  container: ViewContainerRef;
  submit$: Observable<Event>;
  expressionResult: boolean;

  @Input() customErrors = {};

  constructor(private vcr: ViewContainerRef,
              private resolver: ComponentFactoryResolver,
              @Optional() controlErrorContainer: ControlErrorContainerDirective,
              @Inject(FORM_ERRORS) private errors,
              @Optional() @Host() private form: FormSubmitDirective,
              private controlDir: NgControl, private element: ElementRef) {
    this.container = controlErrorContainer ? controlErrorContainer.vcr : vcr;
    // element.nativeElement.classList.add('error');
    this.submit$ = this.form ? this.form.submit$ : EMPTY;
  }

  ngOnInit() {
    merge(
      this.submit$,
      this.control.valueChanges
    ).pipe(
      untilDestroyed(this)).subscribe((v) => {
        const fg = this.element.nativeElement.closest('.form-group');
        if (fg) {
          fg.classList.remove('ng-invalid', 'ng-valid',
                    'ng-pristine', 'ng-touched',
                    'ng-dirty', 'ng-untouched');
          if (this.control.invalid) {
            fg.classList.add('ng-invalid');
          } else {
            fg.classList.add('ng-valid');
          }
          if (this.control.dirty) {
            fg.classList.add('ng-dirty');
          } else {
            fg.classList.add('ng-pristine');
          }
          if (this.control.touched) {
            fg.classList.add('ng-touched');
          } else {
            fg.classList.add('ng-untouched');
          }
        }



        // const controlStatus = this.control.status;
        const controlErrors = this.control.errors;
        /* controlTouch permette di non rendere visibile all'avvio del form il componente di errore required,
        toglierlo se si attiva al submit */
        const controlTouch = this.control.pristine;
        if (controlErrors) { // && !controlTouch) {


          // const firstKey = Object.keys(controlErrors)[0];
          // const getError = this.errors[firstKey];
          // const text = this.customErrors[firstKey] || getError(controlErrors[firstKey]);

          const errorsArray = [];
          Object.keys(controlErrors).forEach( k => {
            const getError = this.errors[k];
            errorsArray.push({
              text: getError(controlErrors[k]),
              code: k,
            });
          });
          this.setError(errorsArray);
        } else if (this.ref) {
          this.setError(null);
        }
      });
  }

  get control() {
    return this.controlDir.control;
  }

  setError(errors: string[]) {
    if (!this.ref) {
      const factory = this.resolver.resolveComponentFactory(ControlErrorComponent);
      this.ref = this.container.createComponent(factory);
    }

    this.ref.instance.errors = errors;
  }

  ngOnDestroy() { }
}
