/**
 * IMPORT CORE DI ANGULAR
 */

import { NgModule, LOCALE_ID } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { registerLocaleData } from '@angular/common';
import localeit from '@angular/common/locales/it';


/**
 * IMPORT TERZE PARTI
 */

/**
 * IMPORT DELL'APPLICAZIONE
 */

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { HttpErrorsInterceptor } from './shared/_interceptor/http-errors.interceptor';
import { MainModule } from './main/main.module';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';

registerLocaleData(localeit, 'it');


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CoreModule,
    SharedModule,

    AuthModule,
    MainModule,
    AppRoutingModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorsInterceptor,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'it' },
    // {provide: NgbDateParserFormatter, useClass: NgbDateITParserFormatter}
    // "2019-07-03T12:54:00Z"
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
