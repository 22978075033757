import { Component, OnInit, OnDestroy } from '@angular/core';
import { Options } from 'ng5-slider';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth/_service/auth.service';
import {TranslateService} from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  constructor(private router: Router) {}

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0);
    });
  }

  ngOnDestroy() {}



}
