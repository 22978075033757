import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';

export class ApiService {
  opere: any = [];

  public languagesArray = [
    {
      code: 'ita',
      description: 'Italiano',
      default: true
    },
    {
      code: 'eng',
      description: 'English',
      default: false,
    },
    {
      code: 'rus',
      description: 'Русский',
      default: false,
    }
  ];



  constructor(private http: HttpClient) { }
  baseUrl = environment.apiUrl + '/v1/opere';

  /*
   * Prende tutti i dati dei libri presenti nel db
   */
  getAll() {
    if (this.opere.length > 0) {
      return of(this.opere);
    } else {
      return this.http.get(this.baseUrl + '/').pipe( map( (r: any)  => {
      this.opere = r;
      return r;
      }));
    }
  }

  /*
   * Ritorna i dati di un libro specifico tramite id
   * @param: id;
   */
  getById(id: number) {
    let t;
    delete sessionStorage.opere;
    return this.getAll()
      .pipe(map( opere => {
        let o;
        opere.forEach(opera => {
          if ( Number(opera.n_bibid) === Number(id)) {
              o = opera;
          }
        });
        return o;
    }));

  }

  /*
   * Aggiorna il libro modificato
   * @param: book;
   */
  updateBook(id: number, jsonIn: any) {
    return this.http.put(this.baseUrl + '/' + id, jsonIn);
  }

  addBook(opera: any) {
    return this.http.post(this.baseUrl + '/', opera);
  }

  /*
   * Elimina il libro selezionato tramite id
   * @param: id;
   */

  deleteBookbyId(bookId: any) {
    return this.http.delete(this.baseUrl + '/' + bookId).subscribe(
      data => {
        this.getAll();
        location.reload();
      });
  }

  /*
  * Prende tutti i dati dei libri presenti nel db
  */
  getAllLanguages() {
    // return this.httpClient.get(this.baseUrl + '/lingue/');
    return of(this.languagesArray);
  }

  /*
    * Ritorna i dati di un codice libro specifico tramite id
    * @param: code;
    */
  getByCode(code: string) {
    // return this.httpClient.get(this.baseurl + code);
    for (let i = 0; i < this.languagesArray.length; i++) {
      if (this.languagesArray[i].code === code) {
        return of(this.languagesArray[i]);
      }
    }
  }

}

