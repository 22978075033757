import { NgModule } from '@angular/core';

import { SafePipe } from './_pipe/safe.pipe';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { Ng5SliderModule } from 'ng5-slider';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { BlockUIModule } from 'ng-block-ui';
import { BlockUIHttpModule } from 'ng-block-ui/http';
import { HttpRequest } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { AlertModalComponent } from './modals/alert-modal/alert-modal.component';
import { TagCloudModule } from 'angular-tag-cloud-module';


// import { LazyLoadImageModule, intersectionObserverPreset } from 'ng-lazyload-image';
// @Injectable()
// export class NgbDateITParserFormatter extends NgbDateParserFormatter {
//   parse(value: string): NgbDateStruct {
//     if (!value) { return null; }

//     const parts = value.trim().split('/');

//     return {
//       day: parts.length > 0 ? parseInt(parts[0], 10) : null,
//       month: parts.length > 1 ? parseInt(parts[1], 10) : null,
//       year: parts.length > 2 ? parseInt(parts[2], 10) : null,
//     };
//   }

//   format(date: NgbDateStruct): string {
//     const pad = (n: any) => Number.isInteger(n) ? ('0' + n).substr(-2) : '';
//     return date ? `${pad(date.day)}/${pad(date.month)}/${date.year}` : '';
//   }
// }


@NgModule({
  declarations: [
    SafePipe,
    AlertModalComponent,
  ],
  imports: [
    CommonModule,
    NgxDatatableModule,
    Ng5SliderModule,
    UiSwitchModule,
    NgbModule,
    BlockUIModule,
    NgSelectModule,
    CKEditorModule,
    FormsModule,
    ReactiveFormsModule,
    TagCloudModule
  ],
  exports: [
    SafePipe,
    CommonModule,
    FormsModule,

    NgSelectModule,
    NgxDatatableModule,
    Ng5SliderModule,
    UiSwitchModule,
    NgbModule,
    BlockUIModule,
    TranslateModule,
    CKEditorModule,
    TagCloudModule
  ],
  providers: [

  ],
  entryComponents: [
    AlertModalComponent
  ]
})
export class SharedModule { }
