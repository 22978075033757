import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';

export class AuthService {

  constructor(private http: HttpClient) {}

  login(postData: any) {
    return of({});
    return this.http.post(environment.apiUrl + '/public/login', postData)
      .pipe(map( (user: any) => {
        if (user && user.data && user.data.jwtToken) {
          this.setToken(user.data.jwtToken.replace('Bearer ', ''));
          return true;
        }
        throw new Error('Valid token not returned');
      }));
  }

  setToken(token: string) {
    localStorage.token = token;
  }
}
