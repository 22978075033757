
import { Injectable, NgZone } from '@angular/core';
import { HttpRequest, HttpResponse, HttpErrorResponse,
         HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Router } from '@angular/router';
// import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class HttpErrorsInterceptor implements HttpInterceptor {


  constructor(private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
        .pipe(
          tap(() => {
            // in caso di successo dovrei andare a vedere se c'è un token nuovo
          }, error => {
            if (error.errorMessage) {
                // errore codificato in API
                alert(error.errorMessage);
              } else if (error.message) {
                // errrore generico (CORS, offline etc)
                alert(error.message);
              }

          })
        );
  }
}
