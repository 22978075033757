import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/shared/_service/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import {map} from 'rxjs/operators';
import { Options } from 'ng5-slider';

@Component({
  selector: 'app-esplora',
  templateUrl: './esplora.component.html'
})
export class EsploraComponent implements OnInit {
  cacheConteggi: any = {};
  filtriApplicati = false;
  opere: any = []; // tutte le opere che arrivano dal servizio
  opereFiltrate: any; // tutte le opere che soddisfano i criteri della ricerca
  opereTrovate: any; // tutte le opereFiltrate che soddisfano i criteri del filtro
  openAutore = true;
  openCitate = true;
  openOpere = true;
  openSoggetto = true;
  openSoggettoA = true;
  openSoggettoG = true;
  openSoggettoGe = true;
  openSoggettoI = true;
  openChiave = true;
  autore: string;
  ricercaAvanzataFiltra: any;
  value: number;
  options: Options = {
    floor: 1930,
    ceil: (new Date()).getFullYear() + 1,
    step: 2,
    animate: false,
    showTicks: true,
    noSwitching: true,
    getLegend: (value: any): string => {
      if (value % 10 === 0) {
        return value;
      }
    }
  };

  filtro: any;
  searchAutoriCuratori;
  searchOpereCitate;
  searchOpere;
  searchSoggetti;
  searchKey;
  opereCitateFiltrate;
  autoriCuratoriFiltrati;
  soggettiFiltratiGen;
  soggettiFiltratiGeo;
  soggettiFiltratiIst;
  titoliOpereFiltrate;
  keywordsFiltrate;
  soggettiFiltratiAutori;
  arrayTitoliRicerca;
  arrayAutoriRicerca;
  arrayLingueRicerca;
  arrayChiaveRicerca;
  arraySoggettoRicerca;
  ready = false;
//  checboxAutoriCuratori = new Set();
  // marked = false;
  lingua = localStorage.lang;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService) {
  }

  refresh($event) {
    this.ngOnInit();
  }

  ngOnInit() {
    if (window.innerWidth < 1024) {
      this.openAutore = false;
      this.openCitate = false;
      this.openOpere = false;
      this.openSoggetto = false;
      this.openSoggettoA = false;
      this.openSoggettoG = false;
      this.openSoggettoGe = false;
      this.openSoggettoI = false;
      this.openChiave = false;
    }
    this.ready = false;
    this.cacheConteggi = {};
    this.filtro = {
      autoreCuratore: [],
      titolo: [],
      lingua: '',
      soggetto: [],
      chiave: [],
      opereCitate: [],
      suGrossman: null,
      diGrossman: null,
      monografie: null,
      seriali: null,
      libera: '',
      minAnno: 1930,
      maxAnno: (new Date()).getFullYear() + 1,
    };
    if (localStorage.filtroEsplora) {
      this.filtro = JSON.parse(localStorage.filtroEsplora);
    }
    if (!this.filtro.minAnno) {
      this.filtro.minAnno = 1930;
    }
    if (!this.filtro.maxAnno) {
      this.filtro.maxAnno = (new Date()).getFullYear() + 1;
    }

    this.api.getAll().subscribe(opere => {
      this.opere = opere;
      this.opere.forEach(element => {
        if ( element.categoria === 'S') {
          element.segnatura = false;
        } else if (element.categoria === 'P') {
          element.segnatura = true;
        }
      });

      this.search();
    });
  }

  getAutoriCuratori(opere) {
    const autoriCuratori = new Set();
    let array;
    if (!opere || opere.length === 0) {
      return [];
    }
    opere.forEach(obj => {
      obj.valori.sn_author.forEach(autori => {
        if (autori.sn_field_data_ita) {
          autoriCuratori.add(autori.sn_field_data_ita);
        } else {
          autoriCuratori.add(autori.sn_field_data_rus);
        }

        autoriCuratori.delete('');
        autoriCuratori.delete(null);
      });
      obj.valori.sn_curator.forEach(curatori => {
        if (curatori.sn_field_data_ita) {
          autoriCuratori.add(curatori.sn_field_data_ita);
        } else {
          autoriCuratori.add(curatori.sn_field_data_rus);
        }
        autoriCuratori.delete('');
        autoriCuratori.delete(null);
      });
    });
    autoriCuratori.delete('');
    array = Array.from(autoriCuratori);
    array.sort();
    return array;
  }

  getTitoli(opere) {
    const titolo = new Set();
    let array;
    if (!opere || opere.length === 0) {
      return [];
    }
    opere.forEach(obj => {
      if (obj.s_titolo_ita) {
        titolo.add(obj.s_titolo_ita);
      } else {
        titolo.add(obj.sn_title);
      }
    });

    titolo.delete('');
    titolo.delete(null);
    array = Array.from(titolo);
    array.sort();
    return array;
  }

  getLingue(opere) {
    const lingue = new Set();
    let array;
    if (!opere || opere.length === 0) {
      return [];
    }
    opere.forEach(lingua => {
      lingue.add(lingua.s_field_41_a);
    });
    lingue.delete('');
    lingue.delete(null);
    array = Array.from(lingue);
    array.sort();
    return array;
  }

  getSoggetti(opere) {
    const soggetti = new Set();
    let array;
    if (!opere || opere.length === 0) {
      return [];
    }

    opere.forEach(obj => {
      obj.valori['600_x'].forEach(soggGenerale => {
        soggetti.add(soggGenerale['sn_field_data_' + this.lingua]);
      });
      obj.valori['600_a'].forEach(soggAutore => {
        soggetti.add(soggAutore['sn_field_data_' + this.lingua]);
      });
      obj.valori['651_a'].forEach(soggGeo => {
        soggetti.add(soggGeo['sn_field_data_' + this.lingua]);
      });
      obj.valori['610_a'].forEach(soggIst => {
        soggetti.add(soggIst['sn_field_data_' + this.lingua]);
      });
    });
    soggetti.delete(null);
    soggetti.delete('');
    array = Array.from(soggetti);
    array.sort();
    return array;
  }

  getChiave(opere) {
    const chiavi = new Set();
    let array;
    if (!opere || opere.length === 0) {
      return [];
    }

    opere.forEach(obj => {
      obj.valori['654_a'].forEach(chiave => {
        chiavi.add(chiave['sn_field_data_' + this.lingua]);
        chiavi.delete('');
        chiavi.delete(null);
      });
    });
    array = Array.from(chiavi);
    array.sort();
    return array;
  }

  getOpereCitate(opere) {
    const citate = new Set();
    let array;
    if (!opere || opere.length === 0) {
      return [];
    }

    opere.forEach(obj => {
      obj.valori['600_t'].forEach(operaCit => {
        citate.add(operaCit['sn_field_data_' + this.lingua]);
        citate.delete('');
        citate.delete(null);
      });
    });
    array = Array.from(citate);
    array.sort();
    return array;
  }

  getSoggettiGenerali(opere) {
    const soggetti = new Set();
    let array;
    if (!opere || opere.length === 0) {
      return [];
    }

    opere.forEach(obj => {
      obj.valori['600_x'].forEach(soggGenerale => {
        soggetti.add(soggGenerale['sn_field_data_' + this.lingua]);
      });
    });
    soggetti.delete(null);
    soggetti.delete('');
    array = Array.from(soggetti);
    array.sort();
    return array;
  }

  getSoggettiAutori(opere) {
    if (!opere || opere.length === 0) {
      return [];
    }

    const soggetti = new Set();
    let array;
    opere.forEach(obj => {
      obj.valori['600_a'].forEach(soggAutore => {
        soggetti.add(soggAutore['sn_field_data_' + this.lingua]);
      });
    });
    soggetti.delete(null);
    soggetti.delete('');
    array = Array.from(soggetti);
    array.sort();
    return array;
  }

  getSoggettiGeo(opere) {
    const soggetti = new Set();
    let array;
    if (!opere || opere.length === 0) {
      return [];
    }
    opere.forEach(obj => {
      obj.valori['651_a'].forEach(soggGeo => {
        soggetti.add(soggGeo['sn_field_data_' + this.lingua]);
      });
    });
    soggetti.delete(null);
    soggetti.delete('');
    array = Array.from(soggetti);
    array.sort();
    return array;
  }

  getSoggettiIst(opere) {
    const soggetti = new Set();
    let array;
    if (!opere || opere.length === 0) {
      return [];
    }

    opere.forEach(obj => {
      obj.valori['610_a'].forEach(soggIst => {
        soggetti.add(soggIst['sn_field_data_' + this.lingua]);
      });
    });
    soggetti.delete(null);
    soggetti.delete('');
    array = Array.from(soggetti);
    array.sort();
    return array;
  }

  search() {
    this.ready = false;
    delete this.ricercaAvanzataFiltra;
    let filtroRicercaAvanzata: any = {};
    if (localStorage.filtroRicercaAvanzata) {
      filtroRicercaAvanzata = JSON.parse(localStorage.filtroRicercaAvanzata);
      this.ricercaAvanzataFiltra = filtroRicercaAvanzata;
    }

    this.opereTrovate = this.opere.filter(opera => {

      if (filtroRicercaAvanzata.titolo && !this.controllaTitolo(opera, filtroRicercaAvanzata.titolo)) {
        return false;
      }
      if (filtroRicercaAvanzata.autoreCuratore && !this.controllaAutore(opera, filtroRicercaAvanzata.autoreCuratore)) {
        return false;
      }
      if (filtroRicercaAvanzata.lingua && !this.controllaLingua(opera, filtroRicercaAvanzata.lingua)) {
        return false;
      }
      if (filtroRicercaAvanzata.soggetto && !this.controllaSoggetto(opera, filtroRicercaAvanzata.soggetto)) {
        return false;
      }

      if (filtroRicercaAvanzata.chiave && !this.controllaChiave(opera, filtroRicercaAvanzata.chiave)) {
        return false;
      }
      if (filtroRicercaAvanzata.diGrossman !== filtroRicercaAvanzata.suGrossman) {
        if (filtroRicercaAvanzata.diGrossman && !this.controllaOperadiGrossman(opera, filtroRicercaAvanzata.diGrossman)) {
          return false;
        }
        if (filtroRicercaAvanzata.suGrossman && !this.controllaOperasuGrossman(opera, filtroRicercaAvanzata.suGrossman)) {
          return false;
        }
      }

      if (filtroRicercaAvanzata.monografie !== filtroRicercaAvanzata.seriali) {
        if (filtroRicercaAvanzata.monografie && !this.controlloMonografie(opera, filtroRicercaAvanzata.monografie)) {
          return false;
        }
        if (filtroRicercaAvanzata.seriali && !this.controlloSeriali(opera, filtroRicercaAvanzata.seriali)) {
          return false;
        }
      }

      if (filtroRicercaAvanzata.libera && !this.controllaRicercaLibera(opera, filtroRicercaAvanzata.libera)) {
        return false;
      }
      return true;
    });

    this.arrayAutoriRicerca = this.getAutoriCuratori(this.opere);
    this.arrayTitoliRicerca = this.getTitoli(this.opere);
    this.arrayLingueRicerca = this.getLingue(this.opere);
    this.arraySoggettoRicerca = this.getSoggetti(this.opere);
    this.arrayChiaveRicerca = this.getChiave(this.opere);
    this.filtra();
  }

  filtra() {
    this.filtriApplicati = false;
    window.scrollTo(0, 0);
    localStorage.filtroEsplora = JSON.stringify(this.filtro);
    this.opereFiltrate = this.opereTrovate.filter(opera => {
      if (this.filtro.autoreCuratore && this.filtro.autoreCuratore.length > 0) {
        this.filtriApplicati = true;
        return this.controllaAutore(opera, this.filtro.autoreCuratore, true);
      }
      return true;
    });

    this.opereFiltrate = this.opereFiltrate.filter(opera => {
      if (opera.s_anno) {
        if (this.filtro.minAnno && Number(this.filtro.minAnno) > Number(opera.s_anno.replace(/\D/g, ''))) {
          return false;
        }
        if (this.filtro.maxAnno && Number(this.filtro.maxAnno) < Number(opera.s_anno.replace(/\D/g, ''))) {
          return false;
        }
      }

      if (this.filtro.monografie || this.filtro.seriali) {
        this.filtriApplicati = true;
      }
      if (this.filtro.monografie !== this.filtro.seriali) {
        if (this.filtro.monografie && !this.controlloMonografie(opera, this.filtro.monografie)) {
          return false;
        }
        if (this.filtro.seriali && !this.controlloSeriali(opera, this.filtro.seriali)) {
          return false;
        }
      }

      if (this.filtro.diGrossman || this.filtro.suGrossman) {
        this.filtriApplicati = true;
      }
      if (this.filtro.diGrossman !== this.filtro.suGrossman) {

        if (this.filtro.diGrossman && !this.controllaOperadiGrossman(opera, this.filtro.diGrossman)) {
          return false;
        }
        if (this.filtro.suGrossman && !this.controllaOperasuGrossman(opera, this.filtro.suGrossman)) {
          return false;
        }
      }
      if (this.filtro.titolo && this.filtro.titolo.length > 0) {
        this.filtriApplicati = true;
        return this.controllaTitolo(opera, this.filtro.titolo);
      }
      if (this.filtro.chiave && this.filtro.chiave.length > 0) {
        this.filtriApplicati = true;
        return this.controllaChiave(opera, this.filtro.chiave, true);
      }

      if (this.filtro.traduttore) {
        this.filtriApplicati = true;
        return this.controllaTraduttore(opera, this.filtro.traduttore);
      }

      if (this.filtro.opereCitate && this.filtro.opereCitate.length > 0) {
        this.filtriApplicati = true;
        return this.controllaOpereCitate(opera, this.filtro.opereCitate, true);
      }

      if (this.filtro.soggetto && this.filtro.soggetto.length > 0) {
        this.filtriApplicati = true;
        return this.controllaSoggetto(opera, this.filtro.soggetto);
      }
      return true;
    });

    this.opereFiltrate.sort((a, b) => {
      if (a.s_anno.replace(/\D/g, '') !== b.s_anno.replace(/\D/g, '')) {
        return Number(a.s_anno.replace(/\D/g, '')) - Number(b.s_anno.replace(/\D/g, ''));
      }
      if (a.categoria !== b.categoria) {
        return a.categoria === 'P';
      }
      return Number(a.n_bibid) - Number(b.n_bibid);
    });

    this.getFiltroCheckboxAutoriCuratori();
    this.getFiltroCheckboxOpere();
    this.getFiltroCheckboxOpereCitate();
    this.getFiltroCheckboxSoggetti();
    this.getFiltroCheckboxKeywords();


    this.cacheConteggi.conteggioOpereDiGrossman = this.conteggioOpereDiGrossman();
    this.cacheConteggi.conteggioOpereSuGrossman = this.conteggioOpereSuGrossman();
    this.cacheConteggi.conteggioMonografie = this.conteggioMonografie();
    this.cacheConteggi.conteggioSeriali = this.conteggioSeriali();
    this.cacheConteggi.conteggioAutoreCuratore = {};

    this.autoriCuratoriFiltrati.forEach(acf => {
      this.cacheConteggi.conteggioAutoreCuratore[acf] = this.conteggioAutoreCuratore(acf);
    });
    this.cacheConteggi.conteggioChiave = {};
    this.keywordsFiltrate.forEach(kf => {
      this.cacheConteggi.conteggioChiave[kf] = this.conteggioChiave(kf);
    });
    this.cacheConteggi.conteggioSoggettoAutore = {};
    this.soggettiFiltratiAutori.forEach(kf => {
      this.cacheConteggi.conteggioSoggettoAutore[kf] = this.conteggioSoggettoAutore(kf);
    });
    this.cacheConteggi.conteggioSoggettoGen = {};
    this.soggettiFiltratiGen.forEach(kf => {
      this.cacheConteggi.conteggioSoggettoGen[kf] = this.conteggioSoggettoGen(kf);
    });
    this.cacheConteggi.conteggioSoggettoGeo = {};
    this.soggettiFiltratiGeo.forEach(kf => {
      this.cacheConteggi.conteggioSoggettoGeo[kf] = this.conteggioSoggettoGeo(kf);
    });
    this.cacheConteggi.conteggioSoggettoIst = {};
    this.soggettiFiltratiIst.forEach(kf => {
      this.cacheConteggi.conteggioSoggettoIst[kf] = this.conteggioSoggettoIst(kf);
    });
    this.cacheConteggi.conteggioOpere = {};
    this.titoliOpereFiltrate.forEach(kf => {
      this.cacheConteggi.conteggioOpere[kf] = this.conteggioOpere(kf);
    });
    this.cacheConteggi.conteggioOperaCitata = {};
    this.opereCitateFiltrate.forEach(kf => {
      this.cacheConteggi.conteggioOperaCitata[kf] = this.conteggioOpereCitate(kf);
    });

    this.ready = true;



  }

  controllaTitolo(opera, titolo) {
    if (typeof titolo === 'object') {
      titolo = titolo[0];
    }
    // if (opera.n_bibid == '514') debugger;
    // return (opera.sn_title.indexOf(titolo) !== -1
    //           ||
    //         opera.s_titolo_ita.indexOf(titolo) !== -1
    //         //   ||
    //         // opera.s_field_240_a.indexOf(titolo) !== -1
    //         //   ||
    //         // opera.s_field_765_t.indexOf(titolo) !== -1
    //           );

    return ( (opera.sn_title && opera.sn_title === titolo )
              ||
              (opera.s_titolo_ita && opera.s_titolo_ita === titolo)
              ||
              (opera.s_field_240_a && opera.s_field_240_a === titolo)
              ||
              (opera.s_field_765_t && opera.s_field_765_t === titolo)
              // ||
              // (opera.s_field_240_a && opera.s_field_240_a === titolo)
              // ||
              // (opera.s_field_765_t && opera.s_field_765_t === titolo)
    );
  }



  conteggioOpereDiGrossman() {
    // return 3;
    let count = 0;
    this.opereFiltrate.forEach(opera => {
      if (this.controllaOperadiGrossman(opera, true)) {
        count++;
      }
    });
    return count;
  }

  conteggioOpereSuGrossman() {
    // return 3;
    let count = 0;
    this.opereFiltrate.forEach(opera => {
      if (this.controllaOperasuGrossman(opera, true)) {
        count++;
      }
    });
    return count;
  }

  conteggioSeriali() {
    // return 3;
    let count = 0;
    this.opereFiltrate.forEach(opera => {
      if (this.controlloSeriali(opera, true)) {
        count++;
      }
    });
    return count;
  }

  conteggioMonografie() {
    // return 3;
    let count = 0;
    this.opereFiltrate.forEach(opera => {
      if (this.controlloMonografie(opera, true)) {
        count++;
      }
    });
    return count;
  }

  controllaRicercaLibera(opera, libera) {
    const parolaRicercata = libera.toString();
    const parolaMinuscola = parolaRicercata.toLowerCase();
    let trovata = false;
    Object.keys(opera).forEach((k) => {
      if  (trovata) {
        return;
      }
      if (opera[k]) {
        if (opera[k].toLowerCase && opera[k].toLowerCase().indexOf(parolaMinuscola) !== -1) {
          trovata = true;
        }
      }
    });
    Object.keys(opera.valori).forEach( nomeValore => {
      if  (trovata) {
        return;
      }
      let valori = opera.valori[nomeValore];
      if (!Array.isArray(valori)) {
        valori = [valori];
      }
      valori.forEach(valore => {
        if (valore['sn_field_data_'  + this.lingua].toLowerCase().indexOf(parolaMinuscola) !== -1)  {
          trovata = true;
        }
      });
    });
    return trovata;
  }

  controllaChiave(opera, chiave, isFiltro = false) {

    let trovato = false;
    let ricercaFiltroFallita = false;
    if (typeof chiave === 'string') {
      chiave = [chiave];
    }

    chiave.forEach(obj => {
      let operaTrovato = false;
      opera.valori['654_a'].forEach(chiavi => {
        if (chiavi['sn_field_data_' + this.lingua] === obj) {
          trovato = true;
          operaTrovato = true;
        }
      });
      if (!operaTrovato) {
        ricercaFiltroFallita = true;
      }
    });

    if (ricercaFiltroFallita && isFiltro) {
      return false;
    }
    return trovato;
  }

  controllaOpereCitate(opera, titoli, isFiltro = false) {
    let trovato = false;
    let ricercaFiltroFallita = false;

    titoli.forEach(titolo => {
      let operaTrovato = false;
      opera.valori['600_t'].forEach(oc => {
        if (oc['sn_field_data_' + this.lingua] === titolo) {
          trovato = true;
          operaTrovato = true;
        }
      });
      if (!operaTrovato) {
        ricercaFiltroFallita = true;
      }
    });

    if (ricercaFiltroFallita && isFiltro) {
      return false;
    }
    return trovato;
  }

  controllaTraduttore(opera, traduttore) {
    let trovato = false;
    opera.valori.sn_traduttore.forEach(tr => {
      // if(opera.n_bibid == '495') debugger;
      if (tr.sn_field_data_rus === traduttore) {
        trovato = true;
      }
    });

    return trovato;
  }


  controllaAutore(opera, autori, isFiltro = true) {
    let trovato = false;
    let ricercaFiltroFallita = false;
    if (typeof autori === 'string') {
      autori = [autori];
    }

    autori.forEach(autore => {
      let autoreTrovato = false;
      opera.valori.sn_author.forEach(autoreOpera => {
        if (autoreOpera.sn_field_data_rus === autore || autoreOpera.sn_field_data_ita === autore) {
          trovato = true;
          autoreTrovato = true;
        }
      });
      opera.valori.sn_curator.forEach(curatoreOpera => {
        if (curatoreOpera.sn_field_data_rus === autore || curatoreOpera.sn_field_data_ita === autore) {
          trovato = true;
          autoreTrovato = true;
        }
      });

      if (!autoreTrovato) {
        ricercaFiltroFallita = true;
      }
    });

    if (ricercaFiltroFallita && isFiltro) {
      return false;
    }
    return trovato;

  }

  controllaOperadiGrossman(opera, diGrossman) {
    let trovato = false;
    if (diGrossman && opera.categoria === 'P') {
      trovato = true;
    }
    return trovato;
  }

  controllaOperasuGrossman(opera, suGrossman) {
    let trovato = false;

    if (suGrossman && opera.categoria === 'S') {
      trovato = true;
    }

    return trovato;
  }

  controlloMonografie(opera, monografie) {
    let trovato = false;
    if (monografie && opera.tipologia === 'M') {
      trovato = true;
    }
    return trovato;

  }

  controlloSeriali(opera, seriali) {
    let trovato = false;
    if (seriali && opera.tipologia === 'S') {
      trovato = true;
    }
    return trovato;
  }


  controllaLingua(opera, lingua) {
    return (opera.s_field_41_a.indexOf(lingua) !== -1);
  }

  controllaSoggetto(opera, soggetto) {
    let trovato = false;
    opera.valori['600_x'].forEach(soggGenerale => {
      soggetto.forEach(obj => {
        if (soggGenerale['sn_field_data_' + this.lingua] === obj) {
          trovato = true;
        }
      });
    });

    opera.valori['600_a'].forEach(soggAutore => {
      soggetto.forEach(obj => {
        if (soggAutore['sn_field_data_' + this.lingua] === obj) {
          trovato = true;
        }
      });
    });

    opera.valori['651_a'].forEach(soggGeo => {
      soggetto.forEach(obj => {
        if (soggGeo['sn_field_data_' + this.lingua] === obj) {
          trovato = true;
        }
      });
    });

    opera.valori['610_a'].forEach(soggIst => {
      soggetto.forEach(obj => {
        if (soggIst['sn_field_data_' + this.lingua] === obj) {
          trovato = true;
        }
      });
    });
    return trovato;
  }

  getFiltroCheckboxAutoriCuratori() {
    let res = this.getAutoriCuratori(this.opereFiltrate);
    if (!this.searchAutoriCuratori) {
      this.searchAutoriCuratori = '';
    }
    if (this.searchAutoriCuratori) {
      const parolaRicercata = this.searchAutoriCuratori.toString();
      const parolaMinuscola = parolaRicercata.toLowerCase();
      res = res.filter(autore => {
        if (autore.toLowerCase() && autore.toLowerCase().indexOf(parolaMinuscola) !== -1) {
          return true;
        } else {
          return false;
        }
      });
    }
    this.autoriCuratoriFiltrati = res;
  }

  getFiltroCheckboxOpereCitate() {
    let res = this.getOpereCitate(this.opereFiltrate);
    if (!this.searchOpereCitate) {
      this.searchOpereCitate = '';
    }
    if (this.searchOpereCitate) {
      const parolaRicercata = this.searchOpereCitate.toString();
      const parolaMinuscola = parolaRicercata.toLowerCase();
      res = res.filter(titolo => {
        if (titolo.toLowerCase() && titolo.toLowerCase().indexOf(parolaMinuscola) !== -1) {
          return true;
        } else {
          return false;
        }
      });
    }
    this.opereCitateFiltrate = res;
  }

  getFiltroCheckboxKeywords() {
    let res = this.getChiave(this.opereFiltrate);
    if (this.searchKey) {
      const parolaRicercata = this.searchKey.toString();
      const parolaMinuscola = parolaRicercata.toLowerCase();
      res = res.filter(key => {
        if (key.toLowerCase() && key.toLowerCase().indexOf(parolaMinuscola) !== -1) {
          return true;
        } else {
          return false;
        }
      });

    }
    // console.log(res);
    this.keywordsFiltrate = res;
  }

  getFiltroCheckboxOpere() {
    let res = this.getTitoli(this.opereFiltrate);
    if (this.searchOpere) {
      const parolaRicercata = this.searchOpere.toString();
      const parolaMinuscola = parolaRicercata.toLowerCase();
      res = res.filter(titolo => {
        if (titolo.toLowerCase() && titolo.toLowerCase().indexOf(parolaMinuscola) !== -1) {
          return true;
        } else {
          return false;
        }
      });

    }
    this.titoliOpereFiltrate = res;
  }



  getFiltroCheckboxSoggetti() {
    let resAutori = this.getSoggettiAutori(this.opereFiltrate);
    let resGen = this.getSoggettiGenerali(this.opereFiltrate);
    let resGeo = this.getSoggettiGeo(this.opereFiltrate);
    let resIst = this.getSoggettiIst(this.opereFiltrate);
    if (this.searchSoggetti) {
      const parolaRicercata = this.searchSoggetti.toString();
      const parolaMinuscola = parolaRicercata.toLowerCase();
      resAutori = resAutori.filter(soggetto => {
        if (soggetto.toLowerCase() && soggetto.toLowerCase().indexOf(parolaMinuscola) !== -1) {
          return true;
        } else {
          return false;
        }
      });
      resGen = resGen.filter(soggetto => {
        if (soggetto.toLowerCase() && soggetto.toLowerCase().indexOf(parolaMinuscola) !== -1) {
          return true;
        } else {
          return false;
        }
      });
      resGeo = resGeo.filter(soggetto => {
        if (soggetto.toLowerCase() && soggetto.toLowerCase().indexOf(parolaMinuscola) !== -1) {
          return true;
        } else {
          return false;
        }
      });
      resIst = resIst.filter(soggetto => {
        if (soggetto.toLowerCase() && soggetto.toLowerCase().indexOf(parolaMinuscola) !== -1) {
          return true;
        } else {
          return false;
        }
      });
    }
    this.soggettiFiltratiAutori = resAutori;
    this.soggettiFiltratiGen = resGen;
    this.soggettiFiltratiGeo = resGeo;
    this.soggettiFiltratiIst = resIst;
  }

  conteggioChiave(chiave) {
    let count = 0;
    this.opereFiltrate.forEach(opera => {
      if (this.controllaChiave(opera, [chiave])) {
        count++;
      }
    });
    return count;
  }

  conteggioSoggettoAutore(soggAutore) {
    let count = 0;
    this.opereFiltrate.forEach(opera => {
      if (this.controllaSoggetto(opera, [soggAutore])) {
        count++;
      }
    });
    return count;
  }

  conteggioSoggettoGen(soggGen) {
    let count = 0;
    this.opereFiltrate.forEach(opera => {
      if (this.controllaSoggetto(opera, [soggGen])) {
        count++;
      }
    });
    return count;
  }

  conteggioSoggettoGeo(soggGeo) {
    let count = 0;
    this.opereFiltrate.forEach(opera => {
      if (this.controllaSoggetto(opera, [soggGeo])) {
        count++;
      }
    });
    return count;
  }

  conteggioSoggettoIst(soggIst) {
    let count = 0;
    this.opereFiltrate.forEach(opera => {
      if (this.controllaSoggetto(opera, [soggIst])) {
        count++;
      }
    });
    return count;
  }

  setFiltroTraduttore(traduttore) {
    // TODO: da integrare quando sistemiamo il pannello
    delete this.filtro.traduttore;
    this.filtra();

  }

  setFiltroSoggetto(soggetto) {
    if (!this.filtro.soggetto) {
      this.filtro.soggetto = [];
    }

    if (!this.isSoggettoInFiltro(soggetto)) {
      this.filtro.soggetto.push(soggetto);
    } else {
      for (let i = 0; i < this.filtro.soggetto.length; i++) {
        if (this.filtro.soggetto[i] === soggetto) {
          this.filtro.soggetto.splice(i, 1);
        }
      }
    }
    this.filtra();
  }

  setFiltroChiave(chiave) {
    if (!this.filtro.chiave) {
      this.filtro.chiave = [];
    }

    if (!this.isChiaveInFiltro(chiave)) {
      this.filtro.chiave.push(chiave);
    } else {
      for (let i = 0; i < this.filtro.chiave.length; i++) {
        if (this.filtro.chiave[i] === chiave) {
          this.filtro.chiave.splice(i, 1);
        }
      }
    }
    this.filtra();
  }


  isChiaveInFiltro(chiave) {
    return this.filtro.chiave && this.filtro.chiave.includes(chiave);
  }

  isSoggettoInFiltro(soggAutore) {
    return this.filtro.soggetto && this.filtro.soggetto.includes(soggAutore);
  }

  isAutoreCuratoreInFiltro(autore) {
    return this.filtro.autoreCuratore && this.filtro.autoreCuratore.includes(autore);
  }

  conteggioAutoreCuratore(autore) {
    let count = 0;
    this.opereFiltrate.forEach(opera => {
      if (this.controllaAutore(opera, autore, true)) {
        count++;
      }
    });
    return count;
  }

  isOperaInFiltro(titoloOpera) {
    return this.filtro.titolo && this.filtro.titolo.includes(titoloOpera);
  }

  setFiltroOperaCitata(operaCitata) {
    if (!this.filtro.opereCitate) {
      this.filtro.opereCitate = [];
    }

    if (!this.isOperaCitataInFiltro(operaCitata)) {
      this.filtro.opereCitate.push(operaCitata);
    } else {
      for (let i = 0; i < this.filtro.opereCitate.length; i++) {
        if (this.filtro.opereCitate[i] === operaCitata) {
          this.filtro.opereCitate.splice(i, 1);
        }
      }
    }
    this.filtra();
  }

  isOperaCitataInFiltro(titoloOpera) {
    return this.filtro.opereCitate && this.filtro.opereCitate.includes(titoloOpera);
  }


  conteggioOpere(titoloOpera) {
    let count = 0;
    this.opereFiltrate.forEach(opera => {
      if (this.controllaTitolo(opera, titoloOpera)) {
        count++;
      }
    });
    return count;
  }

  conteggioOpereCitate(titoloOpera) {
    let count = 0;
    this.opereFiltrate.forEach(opera => {
      if (this.controllaOpereCitate(opera, [titoloOpera], false)) {
        count++;
      }
    });
    return count;
  }

  setFiltroAutoreCuratore(autore) {
    if (!this.filtro.autoreCuratore) {
      this.filtro.autoreCuratore = [];
    }

    if (!this.isAutoreCuratoreInFiltro(autore)) {
      this.filtro.autoreCuratore.push(autore);
    } else {
      for (let i = 0; i < this.filtro.autoreCuratore.length; i++) {
        if (this.filtro.autoreCuratore[i] === autore) {
          this.filtro.autoreCuratore.splice(i, 1);
        }
      }
    }
    this.filtra();
  }

  setFiltroOpera(titoloOpera) {
    if (!this.filtro.titolo) {
      this.filtro.titolo = [];
    }

    if (!this.isOperaInFiltro(titoloOpera)) {
      this.filtro.titolo.push(titoloOpera);
    } else {
      for (let i = 0; i < this.filtro.titolo.length; i++) {
        if (this.filtro.titolo[i] === titoloOpera) {
          this.filtro.titolo.splice(i, 1);
        }
      }
    }
    this.filtra();
  }

  /* apertura e chiusura filtri */
  open(tipo, forzaApertura = false) {
    if (tipo === 'autore') {
      this.openAutore = !this.openAutore;
      if (forzaApertura) {
        this.openAutore = true;
      }
    }
    if (tipo === 'citate') {
      this.openCitate = !this.openCitate;
      if (forzaApertura) {
        this.openCitate = true;
      }
    }
    if (tipo === 'opere') {
      this.openOpere = !this.openOpere;
      if (forzaApertura) {
        this.openOpere = true;
      }
    }
    if (tipo === 'soggetto') {
      this.openSoggetto = !this.openSoggetto;
      if (forzaApertura) {
        this.openSoggetto = true;
      }
    }
    if (tipo === 'chiave') {
      this.openChiave = !this.openChiave;
      if (forzaApertura) {
        this.openChiave = true;
      }
    }
  }

  openSoggetti(tipo) {
    if (tipo === 'a') {
      this.openSoggettoA = !this.openSoggettoA;
    }
    if (tipo === 'g') {
      this.openSoggettoG = !this.openSoggettoG;
    }
    if (tipo === 'ge') {
      this.openSoggettoGe = !this.openSoggettoGe;
    }
    if (tipo === 'i') {
      this.openSoggettoI = !this.openSoggettoI;
    }
  }

  ricercaOpereDiGrossman() {
    if (this.filtro.diGrossman === null) {
      this.filtro.diGrossman = true;
      this.filtra();
    } else {
      this.filtro.diGrossman = !this.filtro.diGrossman;
      this.filtra();
    }
  }

  ricercaOpereSuGrossman() {
    if (this.filtro.suGrossman === null) {
      this.filtro.suGrossman = true;
      this.filtra();
    } else {
      this.filtro.suGrossman = !this.filtro.suGrossman;
      this.filtra();
    }
  }

  ricercaSeriali() {
    if (this.filtro.seriali === null) {
      this.filtro.seriali = true;
      this.filtra();
    } else {
      this.filtro.seriali = !this.filtro.seriali;
      this.filtra();
    }
  }

  ricercaMonografie() {
    if (this.filtro.monografie === null) {
      this.filtro.monografie = true;
      this.filtra();
    } else {
      this.filtro.monografie = !this.filtro.monografie;
      this.filtra();
    }
  }
  annullaRicerca() {
    delete localStorage.filtroRicercaAvanzata;
    delete localStorage.filtroEsplora;
    this.search();
  }
}
