import {Component, Input, OnInit, OnDestroy, Output} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html'
})
export class SearchComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  showForm = false;
  filtro: any;
  diGrossman = false;
  @Input() arrayTitoliRicerca: any;
  @Input() arrayAutoriRicerca: any;
  @Input() arrayLingueRicerca: any;
  @Input() arraySoggettoRicerca: any;
  @Input() arrayChiaveRicerca: any;

  @Output() refresh: EventEmitter<boolean> = new EventEmitter();

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.ngOnDestroy();
    this.subscriptions.push(
      this.route.queryParams.subscribe( qp => {
        if (qp.avanzata) {
          this.filtro = {};
          this.showForm = true;
        } else {
          this.showForm = false;
        }
        if (qp.refresh) {
          this.refresh.emit();
        }
      })
    );
    this.filtro = {};
  }

  hide() {
    this.showForm = false;
    this.router.navigate(['/main/esplora']);
  }

  search() {
    delete localStorage.filtroEsplora;
    localStorage.filtroRicercaAvanzata = JSON.stringify(this.filtro);
    this.router.navigate(['/main/esplora']);
    this.showForm = false;
    this.refresh.emit(true);
    // if ( this.router.url === '/main/esplora' ) {
    //      location.reload();
    // } else {
    //
    // }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
