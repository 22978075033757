import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../shared/_service/api.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dettaglio',
  templateUrl: './dettaglio.component.html'
})
export class DettaglioComponent implements OnInit {
  jsonIn: any;
  segnatura = false;
  lingua = localStorage.lang;
  relatedItems: any;
  flag: any = {};
  opere: any;
  constructor(private api: ApiService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    this.api.getById(+id).subscribe(newJsonIn => {
      this.jsonIn = newJsonIn;
      this.relatedItems = [];
      let padre;
      this.api.getAll().subscribe( opere => {
        this.opere = opere;

        if (this.jsonIn.valori.spogli && this.jsonIn.valori.spogli.length > 0) {
          padre = this.jsonIn;
        } else {
          this.opere.forEach((opera) => {
            opera.valori.spogli.forEach(spoglio => {
              if (spoglio.sn_field_data_ita === this.jsonIn.n_bibid) {
                padre = opera;
              }
            });
          });
        }

        if (padre) {
          if (!this.jsonIn.valori.spogli || this.jsonIn.valori.spogli.length === 0) {
            this.relatedItems.push(padre);
            this.flag.related = true;
          }

          this.opere.forEach((opera) => {
            padre.valori.spogli.forEach(spoglio => {
              if (spoglio.sn_field_data_ita == opera.n_bibid && opera.n_bibid !== this.jsonIn.n_bibid) {
                this.relatedItems.push(opera);
                this.flag.related = true;
              }
            });
          });

        }
      });

      this.jsonIn.valori['654_a'].forEach( (k) => {
          if (k['sn_field_data_' + this.lingua]) {
            this.flag.keywords = true;
          }
        });
      this.jsonIn.valori['600_x'].forEach( (k) => {
          if (k['sn_field_data_' + this.lingua]) {
            this.flag.subject = true;
          }
        });
      this.jsonIn.valori['600_a'].forEach( (k) => {
          if (k['sn_field_data_' + this.lingua]) {
            this.flag.subject = true;
          }
        });
      this.jsonIn.valori['651_a'].forEach( (k) => {
          if (k['sn_field_data_' + this.lingua]) {
            this.flag.subject = true;
          }
        });
      this.jsonIn.valori['610_a'].forEach( (k) => {
          if (k['sn_field_data_' + this.lingua]) {
            this.flag.subject = true;
          }
        });

      this.flag.thesaurus = this.flag.keywords || this.flag.subject;


      if ( this.jsonIn.categoria === 'S') {
        this.segnatura = false;
      } else if (this.jsonIn.categoria === 'P') {
        this.segnatura = true;
      }
      window.scrollTo(0, 0);

      });
  }
  cercaAutore(autore) {
    let a;
    if (autore.sn_field_data_ita) {
      a = autore.sn_field_data_ita;
    } else {
      a = autore.sn_field_data_rus;
    }
    localStorage.removeItem('filtroRicercaAvanzata');
    localStorage.filtroEsplora = JSON.stringify({
      autoreCuratore: [ a ]
    });
    this.router.navigate(['/main/esplora']);
  }

  cercaTraduttore(traduttore) {
    localStorage.removeItem('filtroRicercaAvanzata');
    localStorage.filtroEsplora = JSON.stringify({
      traduttore
    });
    this.router.navigate(['/main/esplora']);
  }

  cercaTitolo(titolo) {
    localStorage.removeItem('filtroRicercaAvanzata');
    localStorage.filtroEsplora = JSON.stringify({
      titolo: [ titolo ]
    });
    this.router.navigate(['/main/esplora']);
  }

  cercaKeyword(key) {
    localStorage.removeItem('filtroRicercaAvanzata');
    localStorage.filtroEsplora = JSON.stringify({
      chiave: [ key['sn_field_data_' + localStorage.lang] ]
    });
    this.router.navigate(['/main/esplora']);
  }

  cercaSoggetto(soggetto) {
    localStorage.removeItem('filtroRicercaAvanzata');
    localStorage.filtroEsplora = JSON.stringify({
      soggetto: [ soggetto['sn_field_data_' + localStorage.lang] ]
    });
    this.router.navigate(['/main/esplora']);
  }

  cercaOperaCitata(key) {
    localStorage.removeItem('filtroRicercaAvanzata');
    localStorage.filtroEsplora = JSON.stringify({
      opereCitate: [ key['sn_field_data_' + localStorage.lang] ]
    });
    this.router.navigate(['/main/esplora']);
  }

  navigate(opera) {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate(['/main/dettaglio', opera.n_bibid]);
    });
  }

}
