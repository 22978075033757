import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainHomeComponent } from './home/home.component';
import { MainComponent } from './main.component';
import { EsploraComponent } from './esplora/esplora.component';
import { DettaglioComponent } from './dettaglio/dettaglio.component';

const routes: Routes = [
  {
    path: 'main', component: MainComponent,
    children: [
      { path: 'home', component: MainHomeComponent },
      { path: 'esplora', component: EsploraComponent },
      { path: 'dettaglio', component: DettaglioComponent },
      { path: 'dettaglio/:id', component: DettaglioComponent }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MainRoutingModule { }
