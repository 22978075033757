/**
 * IMPORT CORE DI ANGULAR
 */

import { NgModule } from '@angular/core';

import { MainRoutingModule } from './main.routing';
import { MainHomeComponent } from './home/home.component';
import { MainComponent } from './main.component';
import { SharedModule } from '../shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormValidationComponent } from './form-validation/form-validation.component';
import { ControlErrorsDirective } from './form-validation/control-errors.directive';
import { FormSubmitDirective } from './form-validation/form-submit.directive';
import { ControlErrorComponent } from './form-validation/control-error/control-error.component';
import { ControlErrorContainerDirective } from './form-validation/control-error-container.directive';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SearchComponent } from './search/search.component';
import { EsploraComponent } from './esplora/esplora.component';
import { ApiService } from '../shared/_service/api.service';
import { DettaglioComponent } from './dettaglio/dettaglio.component';

@NgModule({
  declarations: [
    MainComponent,
    MainHomeComponent,
    FormValidationComponent,
    ControlErrorsDirective,
    ControlErrorContainerDirective,
    FormSubmitDirective,
    ControlErrorComponent,
    HeaderComponent,
    FooterComponent,
    SearchComponent,
    EsploraComponent,
    DettaglioComponent
  ],
  imports: [
    SharedModule,
    MainRoutingModule
  ],
  exports: [],
  entryComponents: [ControlErrorComponent],
  providers: [ApiService]
})
export class MainModule { }
