import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { Options } from 'ng5-slider';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CloudData, CloudOptions } from 'angular-tag-cloud-module';
import { ApiService } from 'src/app/shared/_service/api.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-main-home',
  templateUrl: './home.component.html'
})
export class MainHomeComponent implements OnInit, OnDestroy {
  @BlockUI() blockUI: NgBlockUI;
  @BlockUI('form-element') blockUIForm: NgBlockUI;
  public Editor = ClassicEditor;
  opere: any;
  tag = {};
  myOptionsTagCloud: any = [];
  data: CloudData[] = [];
  rotation = [-90, 0];
  value = 100;
  options: Options = {
    floor: 0,
    ceil: 250
  };
  opzioni: CloudOptions = {
    // if width is between 0 and 1 it will be set to the size of the upper element multiplied by the value
    width: 960,
    height: 650,
    font: ' "Open Sans", sans-serif '
  };

  constructor(private router: Router,
              private api: ApiService) {}

  ngOnInit() {
    localStorage.removeItem('filtroRicercaAvanzata');
    localStorage.removeItem('filtroEsplora');

    this.api.getAll().subscribe(opere => {
      this.opere = opere;
      // if (sessionStorage.tagCloud && sessionStorage.tagCloud.length && sessionStorage.tagCloud.length > 0) {
      //   this.data = JSON.parse(sessionStorage.tagCloud);
      //   return;
      // }
      this.opere.forEach(opera => {
        opera.valori['654_a'].forEach(keyword => {
          if (keyword['sn_field_data_' + localStorage.lang] !== '' ) {
            if (!this.tag[keyword['sn_field_data_' + localStorage.lang]]) {
              this.tag[keyword['sn_field_data_' + localStorage.lang]] = 0;
            }
            this.tag[keyword['sn_field_data_' + localStorage.lang]]++;
          }
        });
      });
      // debugger;

      Object.keys(this.tag).forEach( k => {
        this.tag[k] = Math.floor(this.tag[k] / 5);
        const v = Math.floor(Math.random() * (this.rotation.length));
        const rotato = this.rotation[v];
        if (this.tag[k] === 0) {
          this.tag[k] = 1;
        }
        // if (this.tag[k] >= 11) {
        //   this.tag[k] = 10;
        // }
        this.myOptionsTagCloud.push({
            text: k,
            weight: this.tag[k],
            rotate: rotato
        });
      });
      this.myOptionsTagCloud.sort( (a, b) => (b.weight - a.weight) );
      setTimeout( () => {
        this.data = this.myOptionsTagCloud.slice(0, 100);
        sessionStorage.tagCloud = JSON.stringify(this.data);
      }, 500);
    });

  }
  logClicked(key: CloudData) {
    localStorage.removeItem('filtroRicercaAvanzata');
    localStorage.filtroEsplora = JSON.stringify({
      chiave: [ key.text ]
    });
    this.router.navigate(['/main/esplora']);
  }
  ngOnDestroy() {

  }
  esplora(grossman) {
    localStorage.filtroEsplora = JSON.stringify({ diGrossman: grossman, suGrossman: !grossman });
    this.router.navigate(['/main/esplora']);
  }

  esploraSenzaFiltri() {
    delete localStorage.filtroRicercaAvanzata;
    delete localStorage.filtroEsplora;
    this.router.navigate(['/main/esplora']);
  }
}
