import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html'
})
export class AlertModalComponent implements OnInit {

  @Input() type: string;
  constructor(public modal: NgbActiveModal, public modalService: NgbModal) { }

  ngOnInit() {
  }

}
