/**
 * IMPORT CORE DI ANGULAR
 */

import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TokenInterceptor } from './_interceptor/token.interceptor';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';


import { AuthService } from './_service/auth.service';
import { AuthRoutingModule } from './auth.routing';
import { SharedModule } from '../shared/shared.module';
import { BlockUIModule } from 'ng-block-ui';

@NgModule({
  declarations: [
  ],
  imports: [
    SharedModule,
    AuthRoutingModule,
  ],
  exports: [],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
  ]
})
export class AuthModule { }
