import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/shared/_service/api.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  lingue: any = [];
  linguaSelezionata: any;
  constructor(private router: Router,
              private route: ActivatedRoute,
              private api: ApiService,
              private translate: TranslateService) { }

  ngOnInit() {
    this.api.getAllLanguages().subscribe( (language) => {
      this.lingue = language;
      if (localStorage.lang) {
      this.translate.use(localStorage.lang);
      } else {
        localStorage.lang = 'ita';
      }

      this.lingue.forEach(lingua => {
        if (lingua.code === localStorage.lang) {
          this.linguaSelezionata = lingua;
        }
      });

    });

    this.translate.setDefaultLang('ita');
  }

  changeLingua(lang) {
    localStorage.clear();
    sessionStorage.clear();
    localStorage.lang = lang.code;
    // this.linguaSelezionata = lang;
    // this.translate.use(lang.code);
    window.location.reload();
  }
  esplora() {
    delete localStorage.filtroRicercaAvanzata;
    delete localStorage.filtroEsplora;
    this.router.navigate(['/main/esplora'], { queryParams: { refresh: Math.random() }});
  }

  ricerca() {
    delete localStorage.filtroRicercaAvanzata;
    delete localStorage.filtroEsplora;
    this.router.navigate(['/main/esplora'], { queryParams: { avanzata: true, refresh: Math.random() }});
  }
}
